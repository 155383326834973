import React    from 'react';

export default class Error extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            codes: {
                404: 'Страница не найдена'
            }
        };
    }

    render() {

        const { title } = this.props;

        return (
            <div key="Error404" className="page page-404">
                <div className="s-404">
					<div className="container">
						<div className="inner">
							<span className="title">{title}</span>
							<span className="desc">{this.state.codes[title]}</span>
						</div>
					</div>
                </div>
            </div>
        );
    }
}