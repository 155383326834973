import { initStore }    from '../src/store';
import withRematch      from '../src/utils/withRematch';
import Layout           from '../src/containers/MineLayout';
import Error            from '../src/containers/Error';

const ErrorPage = (props) => {
    return (
        <Layout {...props}>
            <Error lang={props.lang} title={props.title} description={props.description}/>
        </Layout>
    );
};

ErrorPage.getInitialProps = async function(ctx) {
    const { pathname, req, store, res } = ctx;

    let token = req ? req.cookies.token : null;

    await store.dispatch.user.getUserInfoFull({token});

    let curLang = req && req.language ? req.language.split('-')[0] : 'ru';

    return {
        headerClass: '',
        curLang,
        pathname,
        title: res.statusCode,
        description: 'Страница: ' + res.statusCode
    }
};

const mapState = state => ({
    lang: state.lang,
    userInfo: state.user,
    activeOrder: state.orders.activeOrder
});

const mapDispatch = ({ orders: { acceptedOrder, declinedOrder }, user: { getUserInfoFull }, calculator: { searchStop, searchStart, update }, ws: { connect }}) => ({
    getUserInfoFull     :    () => getUserInfoFull(),
    calculatorUpdate:   (data) => update(data),
    wsConnect:          () => { connect() },
    searchStop:         (data) => searchStop(data),
    searchStart:        (data) => searchStart(data),
    acceptedOrder:      (orderId) => acceptedOrder(orderId),
    declinedOrder:      (orderId) => declinedOrder(orderId),
});


export default withRematch(initStore, mapState, mapDispatch)(ErrorPage);
